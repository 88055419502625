export const environment = {
  production: true,
  //LOCAL
  // API_URL: 'http://localhost:4000',
  //AWS
  // API_URL: 'http://3.142.99.211:4000'
  //HEROKU
  // API_URL: 'https://server-admin-mci.herokuapp.com'
  //DIGITAL OCEAN
  API_URL: 'https://admin.pcoriente.com',

  //PHP
  // API_SERVER_PHP: '/archivos-mci',
  API_SERVER_PHP: '/archivos-mci',
  //RUTA DE CONSULTA DE ARCHIVOS E IMAGENES
  // API_VIDEO: `http://localhost/archivos-mci/videos/`,
  API_VIDEO: `/archivos-mci/videos/`
};
