import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ServiciosEmpresaService } from 'app/servicios/servicios_empresa.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogComponent } from 'app/mat-dialog/mat-dialog.component';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';



@Component({
  selector: 'app-servicio-empresa',
  templateUrl: './servicio-empresa.component.html',
  styleUrls: ['./servicio-empresa.component.scss']
})
export class ServicioEmpresaComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;

  ServicioForm = new FormGroup({
    nombreServicio: new FormControl('', [Validators.required]),
    descripcionServicio: new FormControl('', [Validators.required]),
    estadoServicio: new FormControl(1),
    image: new FormControl(undefined)
  })
  idServicio: string = this.obtenerId().id;
  resultados: any = {};
  imagenesServicio: any = [];
  mostrar: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private servicio_empresa: ServiciosEmpresaService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    if (this.idServicio) {
      this.obtenerImagenes();
      this.servicio_empresa.getServicio(this.idServicio).subscribe(response => {
        if (response) {
          //LE ASIGNAMOS AL FORMULARIO EL OBJETO DEL PRODUCTO
          this.ServicioForm.patchValue(response[0])
        }
      });
    }

  }


  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ServicioForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  obtenerImagenes = () => {
    this.servicio_empresa.getServicioImg(this.idServicio).subscribe(response => {
      this.imagenesServicio = response;
      if (this.imagenesServicio.length >= 1) {
        this.mostrar = false;
      }
    });
  }


  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  actualizarServicio = () => {

    let servicio: any = {};
    servicio = this.ServicioForm.value;
    delete servicio.image;
    this.idServicio = this.obtenerId().id;

    this.servicio_empresa.actualizarServicio(this.idServicio, servicio).subscribe(response => {

      if (this.ServicioForm.get('image').value) {
        let image = this.ServicioForm.get('image').value;
        this.ServicioForm.controls['image'].setValue(image._files[0]);
        const servicioData = new FormData();
        servicioData.append('image', this.ServicioForm.get('image').value)
        this.idServicio = this.obtenerId().id;
        this.servicio_empresa.guardarImagenServicio(servicioData, this.idServicio).subscribe(response => {
          this.openSnackBar(`¡REGISTRO ${this.idServicio} ACTUALIZADO!`)
          this.ServicioForm.reset();
          this.router.navigateByUrl('/servicios')
        });
      } else {
        this.openSnackBar(`¡REGISTRO ${this.idServicio} ACTUALIZADO!`)
        this.ServicioForm.reset();
        this.router.navigateByUrl('/servicios')
      }
    });

  }

  guardarServicio = () => {
    let servicio: any = {};
    servicio = this.ServicioForm.value;
    delete servicio.image;

    let obtenerIdServicio;
    this.servicio_empresa.postServicio(servicio).subscribe(response => {
      if (this.ServicioForm.get('image').value) {
        obtenerIdServicio = response;
        this.idServicio = obtenerIdServicio.reps[0].id;

        let image = this.ServicioForm.get('image').value;
        this.ServicioForm.controls['image'].setValue(image._files[0]);
        const servicioData = new FormData();
        servicioData.append('image', this.ServicioForm.get('image').value)
        this.servicio_empresa.guardarImagenServicio(servicioData, this.idServicio).subscribe(response => {
          this.openSnackBar(`SE HA CREADO EL SERVICIO:  ${this.ServicioForm.get('nombreServicio').value}!`)
          this.ServicioForm.reset();
          this.router.navigateByUrl('/servicios')
        });
      } else {
        this.openSnackBar(`SE HA CREADO EL SERVICIO:  ${this.ServicioForm.get('nombreServicio').value}!`)
        this.ServicioForm.reset();
        this.router.navigateByUrl('/servicios')
      }
    });
  }


  eliminarImagen = (idImagekit: string, idImagen: string) => {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará una imegen en forma definitiva`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.servicio_empresa.eliminarImagenServicio(idImagen, idImagekit).subscribe(response => {
          this.openSnackBar(`SE HA ELIMINADO LA IMAGEN`)
          this.obtenerImagenes()
        });
      } else {
      }
    });
  }


}
