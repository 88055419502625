import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { DatosEmpresaService } from '../servicios/datosEmpresa.service';
import { MatTable } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductosService } from 'app/servicios/productos.service';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})

export class CatalogoComponent implements OnInit {

  PdfsForm = new FormGroup({
    pdf: new FormControl(''),
    nombreCatalogo: new FormControl('', [Validators.required])
  });

  catalogo: any = {};

  catalogos: any = [];
  displayedColumns: string[] = ['catalogo', 'estado','eliminar'];
  dataSource = [...this.catalogos];
  labelPosition:number = 1

  @ViewChild('table', { static: true }) table: MatTable<any>;


  constructor(
    private datosEmpresaService: DatosEmpresaService,
    private productosservice: ProductosService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  radioSelected = (datos) =>{

    this.catalogos.forEach(element => {
      if(element.estado == 1){
        element.estado = 0;
        this.productosservice.actualizarDatosPDF(element.idCatalogo, element).subscribe(response =>{
        });
      }
    });
    datos.estado = 1;
    this.productosservice.actualizarDatosPDF(datos.idCatalogo, datos).subscribe(response =>{
      this.openSnackBar('El estado se ha actualizado!!')
      this.obtenerCatalogoPDf()
      this.labelPosition = 1
    });
  }

  ngOnInit() { 
    this.obtenerCatalogoPDf();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarPDF = () => {
    if (this.PdfsForm.get('pdf').value) {
      let pdf = this.PdfsForm.get('pdf').value;
      this.PdfsForm.controls['pdf'].setValue(pdf._files[0]);
      const pdfData = new FormData();
      pdfData.append('pdf', this.PdfsForm.get('pdf').value);
      this.datosEmpresaService.guardarPDF(pdfData,this.PdfsForm.get('nombreCatalogo').value).subscribe(response => {
        this.PdfsForm.reset();
        this.obtenerCatalogoPDf()
      });
    }
  }

  obtenerCatalogoPDf = () =>{
    this.datosEmpresaService.obtenerCatalogoPDF().subscribe(response =>{
      this.dataSource = []
      this.catalogos = response;
      this.catalogos.forEach(catalogo => {
        this.dataSource.push(catalogo);
      });
      this.table.renderRows()
    });
  }

  eliminarPdf = (element) =>{

    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará un PDF en forma definitiva`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.datosEmpresaService.eliminarPDF(element.idCatalogo,element.fileId).subscribe(response =>{
          this.openSnackBar('SE HA ELIMINADO EL CATALOGO!!')
          this.obtenerCatalogoPDf()
        });
      } else {
      }
    });


  }

  
}
