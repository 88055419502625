import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod'
import { Router } from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  API_URI = environment.API_URL;

  constructor(private http: HttpClient, private router: Router) { }

  public auth(credentials) {
    return this.http.post(`${environment.API_URL}/login`, credentials);
  }

  public setToken(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('expiration', data.expiration);
    this.router.navigate(['/dashboard']);
  }

  private getToken() {
    return localStorage.getItem('token');
  }

  private getExpiration() {
    return localStorage.getItem('expiration');
  }

  // public isAuthenticated() {
  //   // Para verificar que el usuario está autenticado: 
  //   // Verificar que hay un token guardado, 
  //   // Verificar la fecha de expiración
  //   // Usar este método para los route guards
  //   let tokenExpiration = this.getExpiration();
  //   if (this.getToken() != null && tokenExpiration != null && parseInt(tokenExpiration) > moment().unix()) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // public logout() {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('expiration');
  //   this.router.navigate(['/login']);
  // }


  logout(){
    window.localStorage.removeItem("usuario");
    return this.http.get(`${this.API_URI}/login`);
  }

  guardarUsuario(data){
      localStorage.setItem('usuario',JSON.stringify({
        authenticated: true,
        user: data.usuario,
        permisos: data.permiso
      }))
      return this.obtenerInfoUsuario();
  }


  obtenerInfoUsuario():any{
    let userData = JSON.parse(localStorage.getItem('usuario'));
    const observable = new Observable(observer => {
        observer.next(userData);
        observer.complete();
    });
    return observable;
  }

  public isAuthenticated() {
    if (localStorage.getItem('usuario') != null && JSON.parse(localStorage.getItem('usuario')).authenticated == true) {
      return true;
    } else {
        return false;
    }
  }

  
  getUsuarios() {
    const result = this.http.get(`${this.API_URI}/login/user/new`)
    return result
  }

  getUsuario(id: string) {
    return this.http.get(`${this.API_URI}/login/${id}`);
  }

  crearUsuario(clasificacion: any) {
    return this.http.post(`${this.API_URI}/login/user/new`, clasificacion);
  }

  updateUsuario(id: string | number, usuario: any): Observable<any> {
    return this.http.put(`${this.API_URI}/login/${id}`, usuario);
  }

  
  eliminarUsuario(idUsuario: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/login/${idUsuario}`);
  }

}
