import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductosService } from 'app/servicios/productos.service';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Console } from 'console';



/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
    selector: 'app-productos',
    templateUrl: './productos.component.html',
    styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {
    displayedColumns: string[] = ['idProducto', 'nombreProducto', 'descripcionProducto', 'nombreClasificacion', 'estado', 'ver', 'eliminar'];
    dataSource: MatTableDataSource<any>;
    productos: any = [];
    medidas: any = [];
    img: any = [];

    // @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private productoservice: ProductosService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar) { }

    ngOnInit() {
        this.obtenerProductos();
    }

    obtenerProductos = () => {
        this.productoservice.getProductos().subscribe(response => {
            this.productos = response;
            this.dataSource = new MatTableDataSource(this.productos);
            this.dataSource.sort = this.sort;
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    eliminarProducto = (id, nombreProducto) => {
        const dialogRef = this.dialog.open(DialogDeleteComponent, {
            data: {
                message: `Se eliminará ${nombreProducto} en forma DEFINITIVA`,
                buttonText: {
                    ok: 'Aceptar',
                    cancel: 'Cancelar'
                }
            }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.productoservice.eliminarProducto(id).subscribe(response => {
                    this.obtenerProductos();
                    this.productoservice.getMedidasProducto(id).subscribe(response => {
                        this.medidas = response;
                        this.medidas.forEach(medida => {
                            this.productoservice.eliminarMedida(medida.idMedida).subscribe(response => {
                            });
                        });
                    });
                    this.productoservice.getProductoImg(id).subscribe(response => {
                        this.img = response;
                        this.img.forEach(img => {
                            this.productoservice.eliminarImagen(img.idImagenProducto, img.fileId).subscribe(response => {

                            });
                        });
                    });
                });
            } else {
            }
        });

    }

    //AVISO TIPO SNACKBAR ANGULAR MATERIAL
    openSnackBar(message: string) {
        this.snackBar.open(message, "Aceptar", { duration: 2000 });
    }


    drop(event: CdkDragDrop<string[]>) {
        let i = 1;
        let array: any = [];
        moveItemInArray(this.productos, event.previousIndex, event.currentIndex);
        this.dataSource = new MatTableDataSource(this.productos);
        this.dataSource.sort = this.sort;
        array = this.productos;
        array.forEach(producto => {
            producto.orden = i;
            delete producto.nombreClasificacion;
            if(producto.estado == 'ACTIVO'){
                producto.estado = 1;
            }else{
                producto.estado = 0;
            }

            this.productoservice.actualizarProducto(producto.idProducto, producto).subscribe(response => {
            });
            i++;

            if(producto.estado == 1){
                producto.estado = 'ACTIVO';
            }else{
                producto.estado = 'INACTIVO';
            }

        });
        // this.ngOnInit()
        this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    }
}

