import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoginService } from '../servicios/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  usuario: any = { intentos: 0 };
  messageLogin: string;
  cargaLogin: boolean = false;

  constructor(
    private authService: LoginService,
    private router: Router
  ) { }

  private validarCampos() {
    if (!this.usuario.usuario || this.usuario.usuario.split(' ').join('') == '') {
      return false;
    }
    if (!this.usuario.contrasena || this.usuario.contrasena.split(' ').join('') == '') {
      return false;
    }
    return true;
  }

  tryToLogin() {
    if (this.validarCampos()) {
      this.cargaLogin = true;
      this.authService.auth(this.usuario).subscribe(
        (data: any) => {
          if (data.message != 'INICIO DE SESIÓN INCORRECTO!!') {
            this.authService.guardarUsuario(data).subscribe(response => {
            })
            if (data.usuario) {
              this.cargaLogin = false;
              this.router.navigate(['/productos']);
              // this.navCtrl.navigateRoot('/inicio');
            }
          }
          else {
            this.messageLogin = 'Usuario/Contraseña inválido';
            this.cargaLogin = false;
          }
        },
        (error) => console.log(error)
      );
    } else {
      this.messageLogin = 'LOS DATOS NO SON VALIDOS';
    }

  }

  // tryToLogin() {
  //   if (this.validarCampos()) {
  //     // Add to attempts
  //     this.usuario.intentos++;
  //     this.messageLogin = null;
  //     console.log(this.usuario);
  //     this.authService.auth(this.usuario).subscribe((data: any) => {
  //       console.log(data)
  //       if (data && typeof (data) == 'object' && data.hasOwnProperty('error')) {
  //         if (data.error) {
  //           this.messageLogin = data.message;
  //         } else {
  //           // this.authService.setToken(data.data);
  //           this.usuario.intentos = 0;
  //           this.messageLogin = null;
  //         }
  //       } else {
  //         this.messageLogin = 'No puede iniciar sesión en este momento';
  //       }
  //     }, error => {
  //       this.messageLogin = 'No puede iniciar sesión en este momento';
  //     })
  //   } else {
  //     this.messageLogin = 'Usuario/Contraseña inválido';
  //   }
  // }

}