import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad } from '@angular/router';
import { LoginService } from './login.service';
@Injectable()
export class LoginGuardService implements CanActivate, CanLoad {
    constructor(public auth: LoginService, public router: Router) { }
    canActivate(): boolean {
        if (!this.auth.isAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['/datosEmpresa']);
            return false;
        }
    }

    canLoad(): boolean {
        if (!this.auth.isAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['/datosEmpresa']);
            return false;
        }
    }

}