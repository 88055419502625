import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod'

@Injectable({
  providedIn: 'root'
})
export class ServiciosEmpresaService {
  API_URI = environment.API_URL;

  constructor(private http: HttpClient) { }

  getServicios() {
    const result = this.http.get(`${this.API_URI}/servicios`)
    return result
  }

  getServicio(id: string) {
    return this.http.get(`${this.API_URI}/servicios/${id}`);
  }

  getServicioImg(idServicio) {
    return this.http.get(`${this.API_URI}/servicios/servicioimg/${idServicio}`);
  }

  postServicio(servicio: FormData) {
    return this.http.post(`${this.API_URI}/servicios`, servicio);
  }

  guardarImagenServicio(image:FormData, idServicio){
    return this.http.post(`${this.API_URI}/servicios/${idServicio}`, image);
  }

  actualizarServicio(id: string | number, actualizarServicio: any): Observable<any> {
    return this.http.put(`${this.API_URI}/servicios/${id}`, actualizarServicio);
  }

  eliminarImagenServicio(idImagen: string, idImageKit:any) {
    return this.http.delete(`${this.API_URI}/servicios/servicioimg/${idImagen}/${idImageKit}`);
  }

  eliminarServicio(idServicio: string) {
    return this.http.delete(`${this.API_URI}/servicios/${idServicio}`);
  }

}
