import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';
import { MatDialogComponent } from 'app/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/servicios/documentos.service';
import { VideoService } from 'app/servicios/video.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-video-detalle',
  templateUrl: './video-detalle.component.html',
  styleUrls: ['./video-detalle.component.scss']
})
export class VideoDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  idVideo: string = this.obtenerId().idVideo;
  VIDEO_HOST = environment.API_VIDEO;


  VideoForm = new FormGroup({
    rutaDocumento: new FormControl(undefined),
    descripcion: new FormControl(''),
    estado: new FormControl(1),
  })

  videos: any = [];
  documento: any = ''

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private servicevideo: VideoService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit() {

    if (this.idVideo) {
      this.servicevideo.getVideo(this.idVideo).subscribe(response => {
        this.VideoForm.patchValue(response[0])
        if(this.VideoForm.get('rutaDocumento').value != ''){
          this.videos = response;
        }
      })
    }



  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.VideoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    let mp4 = this.VideoForm.get('rutaDocumento').value;
    if (this.VideoForm.get('rutaDocumento').value != null) {
      this.VideoForm.controls['rutaDocumento'].setValue(mp4._fileNames)
    }
    this.servicevideo.crearVideos(this.VideoForm.value).subscribe(response => {
      if (this.VideoForm.get('rutaDocumento').value  != null) {
        this.VideoForm.controls['rutaDocumento'].setValue(mp4._files[0]);
        const videoData = new FormData();
        videoData.append('video', this.VideoForm.get('rutaDocumento').value);
        this.serviciodocumento.guardarVideo(videoData).subscribe(response => {
        });
      }
      this.VideoForm.reset()
      this.openSnackBar("VIDEO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/videos')
    });
  }

  actualizarDatos = () => {
    let mp4 = this.VideoForm.get('rutaDocumento').value;
    if (this.VideoForm.get('rutaDocumento').value) {
      this.VideoForm.controls['rutaDocumento'].setValue(mp4._fileNames)
    }

    this.servicevideo.actualizarVideo(this.idVideo, this.VideoForm.value).subscribe(response => {
      if (response) {
        if (this.VideoForm.get('rutaDocumento').value) {
          this.VideoForm.controls['rutaDocumento'].setValue(mp4._files[0]);
          const videoData = new FormData();
          videoData.append('video', this.VideoForm.get('rutaDocumento').value);
          this.serviciodocumento.guardarVideo(videoData).subscribe(response => {
          });
        }
        this.openSnackBar("¡REGISTRO ACTUALIZADO!")
        this.VideoForm.reset()
        this.router.navigateByUrl('/videos')
      }
    });
  }

  eliminarDocumento = (id) => {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará de manera PERMANENTE`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.servicevideo.actualizarEstadoDocumento(id, this.VideoForm.value).subscribe(response=>{
          this.ngOnInit()
          this.openSnackBar("SE HA ELIMINADO EL VIDEO!")
          this.videos.length = 0;
        });
        // this.servicevideo.eliminarVideos(id).subscribe(response => {

        // })
      } else {
      }
    });
  }

  //CONSULTA IMAGE KIT
  // if (this.VideoForm.get('rutaDocumento').value) {
  //   let rutaDocumento = this.VideoForm.get('rutaDocumento').value;
  //   this.VideoForm.controls['rutaDocumento'].setValue(rutaDocumento._files[0]);
  //   const rutaDocumentoData = new FormData();
  //   rutaDocumentoData.append('rutaDocumento', this.VideoForm.get('rutaDocumento').value);
  //   this.servicevideo.guardarrutaDocumento(rutaDocumentoData).subscribe(response => {
  //     this.openSnackBar("SE HA GUARDADO EL VIDEO CORRECTAMENTE")
  //     this.VideoForm.reset();
  //   });
  // }

}
