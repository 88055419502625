import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ProductosService } from '../../servicios/productos.service';
import { ClasificacionesService } from 'app/servicios/clasificaciones.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from 'app/servicios/can-deactive.guard';
import { MatDialogComponent } from 'app/mat-dialog/mat-dialog.component';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';
import { ProductoTable } from 'app/model/producto';




@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})

export class ProductoComponent implements OnInit {


  medidas: any = [];
  // displayedColumns: string[] = ['pulgadas', 'espesor', 'largo', 'peso', 'presentacion', 'material', 'eliminar'];
  // dataSource = [...this.medidas];
  displayedColumns: string[] = Object.keys(ProductoTable);
  dataSchema = ProductoTable;
  dataSource = [...this.medidas];

  obtenerId = () => this.activatedRoute.snapshot.params;

  ProductoForm = new FormGroup({
    nombreProducto: new FormControl('', [Validators.required]),
    descripcionProducto: new FormControl('', [Validators.required]),
    idClasificacion: new FormControl(0),
    estado: new FormControl(1),
    image: new FormControl(undefined)
  })

  CaracteristicasForm = new FormGroup({
    pulgadas: new FormControl('', [Validators.required]),
    // espesor: new FormControl(''),
    largo: new FormControl(''),
    peso: new FormControl(''),
    presentacion: new FormControl(''),
    material: new FormControl('')
  })
  idProducto: string = this.obtenerId().id;
  resultados: any = {};
  clasificaciones: any = [];
  imagenesProd: any = [];
  medidasProductos: any = [];
  cambios: boolean = false;
  confirmacion: boolean = false;
  productos: any = []

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private productosService: ProductosService,
    private clasificacioneservice: ClasificacionesService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {


  }



  ngOnInit() {

    this.obtenerClasificaciones()
    if (this.idProducto) {
      // this.ProductoForm.valueChanges.subscribe(data =>{
      //   this.cambios = true;
      //DETECT CAMBIOS DEL FORMULARIO
      //   console.log(this.cambios)
      // });
      this.obtenerImagenes();
      this.productosService.getProducto(this.idProducto).subscribe(response => {
        if (response) {
          this.obtenerMedidasProducto(this.idProducto)
          //LE ASIGNAMOS AL FORMULARIO EL OBJETO DEL PRODUCTO
          this.ProductoForm.patchValue(response[0])
        }
      });
    } else {


    }



  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ProductoForm.dirty || this.CaracteristicasForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  editarMedida(row) {
    if (row.id === 0) {
    } else {
      if (row.idMedida) {
        delete row.isEdit
        this.productosService.actualizarMedida(row.idMedida, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerMedidasProducto(this.idProducto)
        });
      } else {
        row.isEdit = false
      }
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  obtenerClasificaciones = () => {
    this.clasificacioneservice.getClasificaciones().subscribe(response => {
      this.clasificaciones = response;
    })
  }

  obtenerImagenes = () => {
    this.productosService.getProductoImg(this.idProducto).subscribe(response => {
      this.imagenesProd = response;
    });
  }

  guardarMedidas = () => {
    if (this.idProducto) {
      this.CaracteristicasForm.addControl('idProducto', new FormControl(''));
      this.CaracteristicasForm.controls['idProducto'].setValue(this.idProducto);
      this.productosService.createMedida(this.CaracteristicasForm.value).subscribe(response => {
        if (response) {
          this.openSnackBar(`SE HA AGREGADO UNA NUEVA MEDIDA!`)
          this.obtenerMedidasProducto(this.idProducto);
        }
      });
    } else {
      this.openSnackBar(`SE HA AGREGADO UNA NUEVA MEDIDA!`)
      this.dataSource.push(this.CaracteristicasForm.value)
      this.table.renderRows();
    }
    this.CaracteristicasForm.reset()
  }

  obtenerMedidasProducto = (idProducto) => {
    this.productosService.getMedidasProducto(idProducto).subscribe(data => {
      this.dataSource = [];
      this.medidasProductos = data;
      this.medidasProductos.forEach(medida => {
        this.dataSource.push(medida)
      });
      this.table.renderRows();
    });
  }

  actualizarProducto = () => {
    let producto: any = {};
    producto = this.ProductoForm.value;
    delete producto.image;
    this.idProducto = this.obtenerId().id;

    this.productosService.actualizarProducto(this.idProducto, producto).subscribe(response => {
      if (this.ProductoForm.get('image').value) {
        let image = this.ProductoForm.get('image').value;
        image._files.forEach(element => {
          this.ProductoForm.controls['image'].setValue(element);
          const productoData = new FormData();
          productoData.append('image', this.ProductoForm.get('image').value)
          this.productosService.guardarImagenProducto(productoData, this.idProducto).subscribe(response => {
          });
        });
        this.openSnackBar(`¡REGISTRO ${this.idProducto} ACTUALIZADO!`)
        this.ProductoForm.reset();
        this.router.navigateByUrl('/productos')
      } else {
        this.openSnackBar(`¡REGISTRO ${this.idProducto} ACTUALIZADO!`)
        this.ProductoForm.reset();
        this.ngOnInit()
        // this.router.navigateByUrl('/productos')
      }
    });
  }

  guardarProducto = () => {
    let producto: any = {};
    producto = this.ProductoForm.value;
    delete producto.image;

    let obtenerIdProducto;

    this.ProductoForm.addControl('orden', new FormControl(0));
    this.productosService.getProductos().subscribe(response => {
      if(response){
        this.productos = response;
        this.ProductoForm.controls['orden'].setValue(this.productos.length + 1)
      }
    });

    this.productosService.crearProducto(producto).subscribe(response => {
      obtenerIdProducto = response;
      this.idProducto = obtenerIdProducto.reps[0].id;
      this.dataSource.forEach(producto => {
        producto.idProducto = this.idProducto
        this.productosService.createMedida(producto).subscribe(response => {
        });
      });

      if (this.ProductoForm.get('image').value) {
        let image = this.ProductoForm.get('image').value;
        image._files.forEach(element => {
          this.ProductoForm.controls['image'].setValue(element);
          const productoData = new FormData();
          productoData.append('image', this.ProductoForm.get('image').value)
          this.productosService.guardarImagenProducto(productoData, this.idProducto).subscribe(response => {
          });
        });
        this.ProductoForm.reset();
        this.router.navigateByUrl('/productos')

      } else {
        this.ProductoForm.reset();
        this.router.navigateByUrl('/productos')
      }

    });
  }

  eliminarImagen = (idImageKit: string, idImagen: string) => {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará una imagen en forma definitiva`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.productosService.eliminarImagen(idImagen, idImageKit).subscribe(response => {
          this.openSnackBar(`SE HA ELIMINADO LA IMAGEN!`)
          this.obtenerImagenes()
        });
      } else {
      }
    });


  }

  eliminarMedida = (idMedida) => {

    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará una medida en forma definitiva`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.productosService.eliminarMedida(idMedida).subscribe(data => {
          this.openSnackBar(`SE HA ELIMINADO UNA MEDIDA!`)
          this.obtenerMedidasProducto(this.idProducto);
        });
      } else {
      }
    });
  }

  imagenselect = () => {
    if (this.idProducto) {
      let image = this.ProductoForm.get('image').value;
      image._files.forEach(element => {
        this.ProductoForm.controls['image'].setValue(element);
        const productoData = new FormData();
        productoData.append('image', this.ProductoForm.get('image').value)
        this.productosService.guardarImagenProducto(productoData, this.idProducto).subscribe(response => {
        if(response){
          this.ProductoForm.controls['image'].setValue(undefined);
          this.obtenerImagenes();
        }
        });
      });
    }

  }

}
