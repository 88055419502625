import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../servicios/login.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

//MENU PARA EL ADMINISTRADOR
export const ROUTES: RouteInfo[] = [
  // { path: '/dashboard', title: 'DASHBOARD', icon: 'pe-7s-graph', class: '' },
  { path: '/datosEmpresa', title: 'DATOS EMPRESA', icon: 'pe-7s-culture', class: '' },
  { path: '/productos', title: 'PRODUCTOS', icon: 'pe-7s-note2', class: '' },
  { path: '/clasificaciones', title: 'CLASIFICACIONES', icon: 'pe-7s-ribbon', class: '' },
  // { path: '/catalogo', title: 'CATÁLOGO DE PRODUCTOS', icon: 'pe-7s-notebook', class: '' },
  { path: '/servicios', title: 'SERVICIOS', icon: 'pe-7s-tools', class: '' },
  { path: '/videos', title: 'VIDEOS', icon: 'pe-7s-film', class: '' },
  { path: '/usuarios', title: 'USUARIOS', icon: 'pe-7s-user', class: '' },

  // { path: '/notifications', title: 'Notifications',  icon:'pe-7s-bell', class: '' },

  // { path: '', title: 'Cerrar sesión', icon: 'pe-7s-left-arrow', class: 'active-pro' },
];

//MENU PARA EL USUARIO
export const ROUTESUSER: RouteInfo[] = [
  // { path: '/dashboard', title: 'DASHBOARD', icon: 'pe-7s-graph', class: '' },
  // { path: '/datosEmpresa', title: 'DATOS EMPRESA', icon: 'pe-7s-culture', class: '' },
  { path: '/productos', title: 'PRODUCTOS', icon: 'pe-7s-note2', class: '' },
  { path: '/clasificaciones', title: 'CLASIFICACIONES', icon: 'pe-7s-ribbon', class: '' },
  // { path: '/catalogo', title: 'CATÁLOGO DE PRODUCTOS', icon: 'pe-7s-notebook', class: '' },
  { path: '/servicios', title: 'SERVICIOS', icon: 'pe-7s-tools', class: '' }

  // { path: '/notifications', title: 'Notifications',  icon:'pe-7s-bell', class: '' },

  // { path: '', title: 'Cerrar sesión', icon: 'pe-7s-left-arrow', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  dataUsuario;
  cerrandoSesion: boolean = false;

  constructor(
    private loginCtrl: LoginService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.loginCtrl.obtenerInfoUsuario().subscribe(response =>{
      this.dataUsuario = response;
      if(this.dataUsuario.permisos == 1){
        this.menuItems = ROUTES.filter(menuItem => menuItem);
      }else{
        this.menuItems = ROUTESUSER.filter(menuItem => menuItem);
      }
    });
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
  cerrarSesion = () => {
    this.cerrandoSesion = true;
    this.loginCtrl.logout().subscribe(response => {
      this.cerrandoSesion = false;
      this.router.navigate(['/login']);
    })
  }
}
