import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod'

@Injectable({
  providedIn: 'root'
})
export class DatosEmpresaService {

  API_URI = environment.API_URL;

  constructor(private http: HttpClient) { }

  getDatos() {
    const result = this.http.get(`${this.API_URI}/datos_empresa`)
    return result
  }

  actualizarDatos(id: string | number, actualizarDatos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/datos_empresa/${id}`, actualizarDatos);
  }
  
  crearTelefono(datosEmpresa: any) {
    return this.http.post(`${this.API_URI}/datos_empresa/telefono`, datosEmpresa);
  }

  crearCorreo(datosEmpresa: any) {
    return this.http.post(`${this.API_URI}/datos_empresa/correo`, datosEmpresa);
  }

  getTelefonos() {
    const result = this.http.get(`${this.API_URI}/datos_empresa/telefono`)
    return result
  }

  getCorreos() {
    const result = this.http.get(`${this.API_URI}/datos_empresa/correo`)
    return result
  }

  eliminarTelefono(idTelefono: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/datos_empresa/telefono/${idTelefono}`);
  }

  eliminarCorreo(idCorreo: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/datos_empresa/correo/${idCorreo}`);
  }

  guardarPDF(pdf:FormData, nombre:string){
    return this.http.post(`${this.API_URI}/productos/catalogo/pdf/${nombre}`, pdf);
  }

  obtenerCatalogoPDF() {
    const result = this.http.get(`${this.API_URI}/productos/catalogo/pdf`)
    return result
  }

  eliminarPDF(idCatalogo: string, idImageKit:any): Observable<any> {
    return this.http.delete(`${this.API_URI}/productos/catalogo/${idCatalogo}/${idImageKit}`);
  }

}
