import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogComponent } from 'app/mat-dialog/mat-dialog.component';
import { LoginService } from 'app/servicios/login.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  idUsuario: string = this.obtenerId().idUsuario;
  resultados: any = {};
  UsuarioForm = new FormGroup({
    usuario: new FormControl('', [Validators.required]),
    contrasena: new FormControl('', [Validators.required]),
    permiso: new FormControl(0, [Validators.required])
  })

  constructor(    private router: Router,
                  private activatedRoute: ActivatedRoute,
                  private snackBar: MatSnackBar,
                  public dialog: MatDialog,
                  public loginservice: LoginService) { }

  ngOnInit(): void {
    if (this.idUsuario) {
      this.loginservice.getUsuario(this.idUsuario).subscribe(response => {
        if (response) {
          //LE ASIGNAMOS AL FORMULARIO EL OBJETO DEL USUARIO
          this.UsuarioForm.patchValue(response[0])
        }
      });
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.UsuarioForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarUsuario = () => {
    if (this.idUsuario) {
      this.loginservice.updateUsuario(this.idUsuario, this.UsuarioForm.value).subscribe(response => {
        if (response) {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          this.UsuarioForm.reset()
          this.router.navigateByUrl('/usuarios')
        }
      });
    } else {
      this.loginservice.crearUsuario(this.UsuarioForm.value).subscribe(response => {
        this.resultados = response;
        this.openSnackBar("¡REGISTRO GUARDADO!")
        this.UsuarioForm.reset()
        this.router.navigateByUrl('/usuarios')
      });
    }

  }

}
