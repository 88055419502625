import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod'

@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  API_URI = environment.API_URL;

  constructor(private http: HttpClient) { }

  crearProducto(producto: FormData) {
    return this.http.post(`${this.API_URI}/productos`, producto);
  }

  actualizarProducto(id: string | number, actualizarProducto: any): Observable<any> {
    return this.http.put(`${this.API_URI}/productos/${id}`, actualizarProducto);
  }

  eliminarProducto(idProducto: string) {
    return this.http.delete(`${this.API_URI}/productos/${idProducto}`);
  }

  getProductos() {
    const result = this.http.get(`${this.API_URI}/productos`)
    return result
  }

  getProducto(id: string) {
    return this.http.get(`${this.API_URI}/productos/${id}`);
  }

  getProductoImg(idProducto) {
    return this.http.get(`${this.API_URI}/productos/productoimg/${idProducto}`);
  }

  guardarImagenProducto(image:FormData, idProducto){
    return this.http.post(`${this.API_URI}/productos/${idProducto}`, image);
  }

  createMedida(medidas: any) {
    return this.http.post(`${this.API_URI}/productos/caracteristicas/porProducto`, medidas);
  }

  actualizarMedida(id: string | number, actualizarMedida: any): Observable<any> {
    return this.http.put(`${this.API_URI}/productos/caracteristicas/${id}`, actualizarMedida);
  }

  getMedidasProducto(id: string) {
    return this.http.get(`${this.API_URI}/productos/caracteristicas/${id}`);
  }

  eliminarMedida(idMedida: string) {
    return this.http.delete(`${this.API_URI}/productos/caracteristicas/${idMedida}`);
  }
  
  eliminarImagen(idImagen: string, idImageKit:any): Observable<any> {
    return this.http.delete(`${this.API_URI}/productos/productoimg/${idImagen}/${idImageKit}`,idImageKit);
  }

  actualizarDatosPDF(id: string | number, pdfDatos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/productos/catalogo/pdf/${id}`, pdfDatos);
  }

}
