import { Routes } from '@angular/router';

import { DatosEmpresaComponent } from '../../datosEmpresa/datosEmpresa.component';
import { ProductosComponent } from '../../productos/productos.component';
import { ProductoComponent } from '../../productos/producto/producto.component';
import { ClasificacionesComponent } from '../../clasificaciones/clasificaciones.component';
import { ClasificacionComponent } from '../../clasificaciones/clasificacion/clasificacion.component';
import { ServiciosComponent } from '../../servicios_empresa/servicios.component';
import { ServicioEmpresaComponent } from '../../servicios_empresa/servicio-empresa/servicio-empresa.component';
import { CatalogoComponent } from '../../catalogo/catalogo.component';
import { CanDeactivateGuard } from 'app/servicios/can-deactive.guard';
import { UsuariosLoginComponent } from 'app/usuarios-login/usuarios-login.component';
import { UsuarioComponent } from 'app/usuarios-login/usuario/usuario.component';
import { VideosComponent } from 'app/videos/videos.component';
import { VideoDetalleComponent } from 'app/videos/video-detalle/video-detalle.component';

export const AdminLayoutRoutes: Routes = [
    { path: 'datosEmpresa', component: DatosEmpresaComponent, canDeactivate: [CanDeactivateGuard]},
    { path: 'productos', component: ProductosComponent },
    { path: 'producto', component: ProductoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'producto/:id', component: ProductoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'clasificaciones', component: ClasificacionesComponent },
    { path: 'clasificacion', component: ClasificacionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'clasificacion/:idClasificacion', component: ClasificacionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'servicios', component: ServiciosComponent },
    { path: 'servicio', component: ServicioEmpresaComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'servicio/:id', component: ServicioEmpresaComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo', component: CatalogoComponent },
    { path: 'usuarios', component: UsuariosLoginComponent},
    { path: 'usuario', component: UsuarioComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'usuario/:idUsuario', component: UsuarioComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'videos', component: VideosComponent},
    { path: 'video-detalle', component: VideoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'video-detalle/:idVideo', component: VideoDetalleComponent, canDeactivate: [CanDeactivateGuard] }

];
