import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';
import { MatDialogComponent } from 'app/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/servicios/documentos.service';
import { ProductosService } from 'app/servicios/productos.service';
import { VideoService } from 'app/servicios/video.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  displayedColumns: string[] = ['idVideo', 'descripcion', 'estado', 'ver', 'eliminar'];
  dataSource: MatTableDataSource<any>;
  videos: any = [];
  medidas: any = [];
  img: any = [];

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private videoservice: VideoService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.obtenerVideos();
  }

  obtenerVideos = () => {
    this.videoservice.getVideos().subscribe(response => {
      this.videos = response;
      this.dataSource = new MatTableDataSource(this.videos);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  eliminarVideo = (id) => {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará el video de forma PERMANENTE`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.videoservice.eliminarVideos(id).subscribe(response => {
          this.obtenerVideos();
        });
      } else {
      }
    });

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    let array: any = [];
    moveItemInArray(this.videos, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.videos);
    this.dataSource.sort = this.sort;
    array = this.videos;
    array.forEach(video => {
      video.orden = i;
      if (video.estado == 'ACTIVO') {
        video.estado = 1;
      } else {
        video.estado = 0;
      }

      this.videoservice.actualizarVideo(video.idVideo, video).subscribe(response => {
      });
      i++;

      if (video.estado == 1) {
        video.estado = 'ACTIVO';
      } else {
        video.estado = 'INACTIVO';
      }
    });
    // this.ngOnInit()
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }

}
