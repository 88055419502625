import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';
import { LoginService } from 'app/servicios/login.service';

@Component({
  selector: 'app-usuarios-login',
  templateUrl: './usuarios-login.component.html',
  styleUrls: ['./usuarios-login.component.scss']
})
export class UsuariosLoginComponent implements OnInit {

  displayedColumns: string[] = ['idUsuario', 'usuario','contrasena', 'permiso','ver', 'eliminar'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  public usuarios: any = [];

  constructor(public loginservice: LoginService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
  this.obtenerUsuarios()
  }

  obtenerUsuarios = () => {
    this.loginservice.getUsuarios().subscribe(data => {
      this.usuarios = data;
      this.dataSource = new MatTableDataSource(this.usuarios);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  } 

  eliminarUsuario = (idUsuario, nombre) =>{
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará el usuario ${nombre} en forma DEFINITIVA`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
          this.loginservice.eliminarUsuario(idUsuario).subscribe(response =>{
              this.obtenerUsuarios();
           
          });
      } else {
      }
    });
  }


}
