import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { DatosEmpresaService } from '../servicios/datosEmpresa.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';


//CUADROS DE DIALOGO
import { MatDialogComponent } from '../mat-dialog/mat-dialog.component';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';


export interface Telefono {
  idTelefono: string;
  telefono: string;
  idEmpresa: string;
}

export interface Correo {
  idCorreo: string;
  correo: string;
  idEmpresa: string;
}


@Component({
  selector: 'app-datosEmpresa',
  templateUrl: './datosEmpresa.component.html',
  styleUrls: ['./datosEmpresa.component.css']
})
export class DatosEmpresaComponent implements OnInit {

  correos: any = [];
  displayedColumnsCorreos: string[] = ['correo', 'eliminar'];
  dataSourceCorreos = [...this.correos];

  telefonos: any = [];
  displayedColumns: string[] = ['telefono', 'eliminar'];
  dataSource = [...this.telefonos];

  @ViewChild('table', { static: true }) table: MatTable<any>;
  @ViewChild('table1', { static: true }) table1: MatTable<any>;

  DatosEmpresaForm = new FormGroup({
    direccion: new FormControl('', [Validators.required]),
    horario: new FormControl('', [Validators.required]),
    acerca_empresa: new FormControl('', [Validators.required]),
  })

  TelefonoForm = new FormGroup({
    telefono: new FormControl('', [Validators.required])
  })

  CorreosForm = new FormGroup({
    correo: new FormControl('', [Validators.email, Validators.required])
  })

  constructor(
    private snackBar: MatSnackBar,
    private datosEmpresaService: DatosEmpresaService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.datosEmpresaService.getDatos().subscribe(response => {
      if (response) {
        this.DatosEmpresaForm.patchValue(response[0])
        this.obtenerTelefonos()
        this.obtenerCorreos()
      }
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.datosEmpresaService.actualizarDatos(1, this.DatosEmpresaForm.value).subscribe(response => {
      this.openSnackBar("¡REGISTRO ACTUALIZADO!")
    });
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.DatosEmpresaForm.dirty) {
      // return confirm('Sus cambios no han sido guardados, ¿ Desea salir sin guardar?');
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  guardarTelefono = () => {
    this.TelefonoForm.addControl('idEmpresa', new FormControl('1'));
    this.TelefonoForm.controls['idEmpresa'].setValue('1');
    this.datosEmpresaService.crearTelefono(this.TelefonoForm.value).subscribe(response => {
      if(response){
        this.obtenerTelefonos();
        this.openSnackBar("SE HA CREADO UN NUEVO TELEFONO")
      }
    });
  }

  obtenerTelefonos = () => {
    this.datosEmpresaService.getTelefonos().subscribe(response => {
      this.dataSource = []
      this.telefonos = response;
      this.telefonos.forEach(tel => {
        this.dataSource.push(tel);
      });
      this.table.renderRows()
      this.TelefonoForm.reset()
    });
  }

  eliminarTelefono = (idTelefono: string) => {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará un telefono en forma definitiva`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.datosEmpresaService.eliminarTelefono(idTelefono).subscribe(response => {
          this.snackBar.open('Se ha eliminado el telefono!', '', {
            duration: 2000,
          });
          this.obtenerTelefonos()
        });
      } else {
      }
    });



  }

  eliminarCorreo = (idCorreo: string) => {

    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará un correo en forma DEFINITIVA`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.datosEmpresaService.eliminarCorreo(idCorreo).subscribe(response => {
          this.snackBar.open('Se ha eliminado el correo!', '', {
            duration: 2000,
          });
          this.obtenerCorreos()
        });
      } else {
      }
    });
  }

  guardarCorreo = () => {
    this.CorreosForm.addControl('idEmpresa', new FormControl('1'));
    this.CorreosForm.controls['idEmpresa'].setValue('1');
    this.datosEmpresaService.crearCorreo(this.CorreosForm.value).subscribe(response => {
      this.obtenerCorreos();
    });
  }

  obtenerCorreos = () => {
    this.datosEmpresaService.getCorreos().subscribe(response => {
      this.dataSourceCorreos = []
      this.correos = response;
      this.correos.forEach(correo => {
        this.dataSourceCorreos.push(correo);
        this.table1.renderRows()
        this.CorreosForm.reset()
      });
    });
  }


  openDialog() {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Esta a punto de eliminar un producto de manera <strong>DEFINITIVA</strong>`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // this.snackBar.open('Closing snack bar in a few seconds', 'Fechar', {
        //   duration: 2000,
        // });
      } else {

      }
    });
  }

}
