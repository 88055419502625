import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductosService } from 'app/servicios/productos.service';
import { ServiciosEmpresaService } from 'app/servicios/servicios_empresa.service';
import { DialogDeleteComponent } from 'app/mat-dialog/dialog-delete/dialog-delete.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface Servicio {
  idServicio: string;
  nombreServicio: string;
  descripcionServicio: string;
  estadoServicio: string;
}


@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  displayedColumns: string[] = ['idServicio', 'nombreServicio', 'descripcionServicio', 'estadoServicio', 'ver', 'eliminar'];
  dataSource: MatTableDataSource<Servicio>;
  public servic: any = [];
  img: any = [];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private servicios_empresa: ServiciosEmpresaService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.servic.length = 0;
    this.obtenerServicios();
  }

  obtenerServicios = () => {
    this.servicios_empresa.getServicios().subscribe(data => {
      this.servic = data;
      this.dataSource = new MatTableDataSource(this.servic);
      this.dataSource.sort = this.sort;

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  eliminarServicio = (id, nombreServicio) => {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        message: `Se eliminará ${nombreServicio} en forma DEFINITIVA`,
        buttonText: {
          ok: 'Aceptar',
          cancel: 'Cancelar'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.servicios_empresa.eliminarServicio(id).subscribe(response => {
          this.obtenerServicios();
          this.servicios_empresa.getServicioImg(id).subscribe(response => {
            this.img = response;
            this.img.forEach(servicio => {
              this.servicios_empresa.eliminarImagenServicio(servicio.idImagenServicio, servicio.fileId).subscribe(response => {

              });
            });
          });
        });
      } else {
      }
    });
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    let array: any = [];
    moveItemInArray(this.servic, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.servic);
    this.dataSource.sort = this.sort;
    array = this.servic;
    array.forEach(servicio => {
      servicio.orden = i;
      if(servicio.estadoServicio == 'ACTIVO'){
          servicio.estadoServicio = 1;
      }else{
          servicio.estadoServicio = 0;
      }

      this.servicios_empresa.actualizarServicio(servicio.idServicio, servicio).subscribe(response => {
      });

      if (servicio.estadoServicio == 1) {
        servicio.estadoServicio = 'ACTIVO';
      } else {
        servicio.estadoServicio = 'INACTIVO';
      }

      i++;
    });
    // this.ngOnInit()
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }

}
