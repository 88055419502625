import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod'

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  API_URI = environment.API_URL;

  constructor(private http: HttpClient) { }

  //IMAGEKIT
  // guardarMP4(mp4:FormData){
  //   return this.http.post(`${this.API_URI}/videos/file/mp4`, mp4);
  // }

  getVideos(){
    return this.http.get(`${this.API_URI}/videos`)
  }

  crearVideos(datos: any) {
    return this.http.post(`${this.API_URI}/videos`, datos);
  }

  eliminarVideos(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/videos/${id}`);
  }
  
  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/videos/estadoVideo/${id}`, datos);
  }

  actualizarVideo(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/videos/${id}`, datos);
  }

  getVideo(id: string) {
    return this.http.get(`${this.API_URI}/videos/${id}`);
  }


}
