import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class DocumentService {

  API_URI = environment.API_URL;
  API_SERVER_PHP = environment.API_SERVER_PHP;

  constructor(private http: HttpClient) { }

  guardarVideo(pdf: FormData) {
    return this.http.post(`${this.API_SERVER_PHP}/insertarVideo.php`, pdf);
  }

}