import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod'

@Injectable({
  providedIn: 'root'
})
export class ClasificacionesService {
  API_URI = environment.API_URL;

  constructor(private http: HttpClient) { }

  getClasificaciones() {
    const result = this.http.get(`${this.API_URI}/clasificacion`)
    return result
  }

  getClasificacion(id: string) {
    return this.http.get(`${this.API_URI}/clasificacion/${id}`);
  }

  crearClasificacion(clasificacion: any) {
    return this.http.post(`${this.API_URI}/clasificacion`, clasificacion);
  }

  actualizarClasificacion(id: string | number, actualizarClasificacion: any): Observable<any> {
    return this.http.put(`${this.API_URI}/clasificacion/${id}`, actualizarClasificacion);
  }

}
