import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClasificacionesService } from '../../servicios/clasificaciones.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogComponent } from 'app/mat-dialog/mat-dialog.component';

@Component({
  selector: 'app-clasificacion',
  templateUrl: './clasificacion.component.html',
  styleUrls: ['./clasificacion.component.scss']
})
export class ClasificacionComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  idClasificacion: string = this.obtenerId().idClasificacion;
  resultados: any = {};
  ClasificacionForm = new FormGroup({
    nombreClasificacion: new FormControl('', [Validators.required]),
  })

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private clasificacionesService: ClasificacionesService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.idClasificacion) {
      this.clasificacionesService.getClasificacion(this.idClasificacion).subscribe(response => {
        if (response) {
          //LE ASIGNAMOS AL FORMULARIO EL OBJETO DE LA CLASIFICACION
          this.ClasificacionForm.patchValue(response[0])
        }
      });
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ClasificacionForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarClasificacion = () => {
    if (this.idClasificacion) {
      this.clasificacionesService.actualizarClasificacion(this.idClasificacion, this.ClasificacionForm.value).subscribe(response => {
        if (response) {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          this.ClasificacionForm.reset()
          this.router.navigateByUrl('/clasificaciones')
        }
      });
    } else {
      this.clasificacionesService.crearClasificacion(this.ClasificacionForm.value).subscribe(response => {
        this.resultados = response;
        this.openSnackBar("¡REGISTRO GUARDADO!")
        this.ClasificacionForm.reset()
        this.router.navigateByUrl('/clasificaciones')
      });
    }

  }


}
