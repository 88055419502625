import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClasificacionesService } from '../servicios/clasificaciones.service';

export interface Clasificacion {
  idClasificacion: string;
  nombreClasificacion: string;
}

@Component({
  selector: 'app-clasificaciones',
  templateUrl: './clasificaciones.component.html',
  styleUrls: ['./clasificaciones.component.css']
})
export class ClasificacionesComponent implements OnInit {

  displayedColumns: string[] = ['idClasificacion', 'nombreClasificacion', 'ver'];
  dataSource: MatTableDataSource<Clasificacion>;
  @ViewChild(MatSort) sort: MatSort;
  public clasificaciones: any = [];

  constructor(
    private clasificacionService: ClasificacionesService
  ) { }

  ngOnInit() {
    this.obtenerClasificaciones();
  }

  obtenerClasificaciones = () => {
    this.clasificacionService.getClasificaciones().subscribe(data => {
      this.clasificaciones = data;
      this.dataSource = new MatTableDataSource(this.clasificaciones);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
